export const ARTICLE_IMAGE_SIZES = {
  S: {
    width: 72,
    height: 72,
  },
  M: {
    width: 187,
    height: 240,
  },
  L: {
    width: 390,
    height: 195,
  },
  XL: {
    width: 784,
    height: 368,
  },
  XXL: {
    width: 900,
    height: 450,
  },
  Share: {
    width: 1024,
    height: 512,
  },
} as const;
