
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TheSliderTitle extends Vue {
  @Prop({
    type: String,
    required: true,
  }) title!: string;
}
