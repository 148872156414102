
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TheSliderButton extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) classes!: object;
}
