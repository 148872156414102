export const eventCategory = {
  home: 'Главная',
  archive: 'Архивы',
  taxonomy: 'Таксономия',
  fromArchive: 'Переходы из архива',
  top: 'Лучшее',
  article: 'Внутренние',
  profile: 'Личный кабинет',
  filter: 'Фильтры',
  toComments: 'Комментарии_переходы',
  publicProfile: 'Публичный профиль автора',
  recipe: 'Рецепт',
  transitionsFromTips: 'Переходы с подсказок',
} as const;

export const eventAction = {
  large: 'Большая акцентная карточка',
  middle: 'Малые акцентные карточки',
  small: 'Списочные карточки',
  largeInWidget: 'Виджет_большая акцентная карточка',
  middleInWidget: 'Виджет_малые акцентные карточки',
  topic: 'Рубрика',
  author: 'Автор',
  newComments: 'Новые комментарии',
  bestCommentForDay: 'Комментарий дня',
  scroll: 'Скролл списка материалов',
  transitionToArticle: 'Переход в материал',
  transition: 'Переход',
  cardInSidebar: 'Закреп в сайдбаре',
  pickUpRecipe: 'Подобрать рецепт',
  filterUsed: 'Любое взаимодействие с фильтром',
  resetFilters: 'Очистить фильтры',
  openingFilters: 'Раскрытие фильтров',
  compressionFilters: 'Сворачивание фильтров',
  startingFillingFieldWithIncludedIngredients: 'Добавить ингредиенты_начать ввод',
  startingFillingFieldWithExcludedIngredients: 'Исключить ингредиенты_начать ввод',
  belowRecipe: 'Под рецептом',
  recipe: 'Рецепт',

  favoritesTransitionsComments: 'Избранное_переходы к комментариям',
  favoritesTransitionsCategory: 'Избранное_переходы к категории',

  favorites: 'Избранное',
  accountDelete: 'Удаление аккаунта',
} as const;
