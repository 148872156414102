export const WIDGETS_NAMES_IN_SERVER = {
  sidebar: 'twenty-sidebar',
  leftSidebar: 'twenty-single-left',
  footer: 'twenty-footer',
  aboveHeader: 'twenty-above-header',
  belowHeader: 'twenty-below-header',
  betweenPosts: 'twenty-between-posts',
  aboveComments: 'twenty-above-comments',
  belowComments: 'twenty-below-comments',
  afterExcerpt: 'twenty-after-excerpt',
  postDynamic: 'twenty-post-dynamic',
  ampAfterFirstParagraph: 'twenty-amp-after-first-paragraph',

  recipesAboveHeader: 'recipes-above-header',
  recipesBelowHeader: 'recipes-below-header',
  recipesListSidebar: 'recipes-list-sidebar',
  recipeSidebar: 'recipe-sidebar',
  recipesAboveComments: 'recipes-above-comments',
  recipesBelowComments: 'recipes-below-comments',
  recipesBelowIngredients: 'recipes-below-ingredients',
  recipesBetweenPosts: 'recipes-between-posts',

  // health
  healthSidebar: 'health-sidebar',
  healthMainPage: 'health-main-page',
  healthAboveMenu: 'health-above-menu',
  healthBelowMenu: 'health-below-menu',
  healthAfterTheFirstParagraph: 'health-after-the-first-paragraph',
  healthPostDynamic: 'health-post-dynamic',
  healthAboveComments: 'health-above-comments',
  healthBelowComments: 'health-below-comments',
  healthFooter: 'health-footer',
  healthScripts: 'health-scripts',
} as const;

type ObjectValues<T> = T[keyof T];

export type WidgetName = ObjectValues<typeof WIDGETS_NAMES_IN_SERVER>;

export const COMMON_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.sidebar,
  WIDGETS_NAMES_IN_SERVER.aboveHeader,
  WIDGETS_NAMES_IN_SERVER.belowHeader,
  WIDGETS_NAMES_IN_SERVER.betweenPosts,
];

export const POST_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.footer,
  WIDGETS_NAMES_IN_SERVER.leftSidebar,
  WIDGETS_NAMES_IN_SERVER.aboveComments,
  WIDGETS_NAMES_IN_SERVER.belowComments,
  WIDGETS_NAMES_IN_SERVER.afterExcerpt,
  WIDGETS_NAMES_IN_SERVER.postDynamic,
];

export const COMMON_RECIPE_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.recipesAboveHeader,
  WIDGETS_NAMES_IN_SERVER.recipesBelowHeader,
  WIDGETS_NAMES_IN_SERVER.recipesListSidebar,
  WIDGETS_NAMES_IN_SERVER.recipeSidebar,
  WIDGETS_NAMES_IN_SERVER.recipesBetweenPosts,
];

export const RECIPE_POST_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.recipesAboveComments,
  WIDGETS_NAMES_IN_SERVER.recipesBelowComments,
  WIDGETS_NAMES_IN_SERVER.recipesBelowIngredients,
];

export const AMP_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.ampAfterFirstParagraph,
];

export const HEALTH_MAIN_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.healthSidebar,
  WIDGETS_NAMES_IN_SERVER.healthMainPage,
  WIDGETS_NAMES_IN_SERVER.healthAboveMenu,
  WIDGETS_NAMES_IN_SERVER.healthBelowMenu,
  WIDGETS_NAMES_IN_SERVER.healthScripts,
];

export const HEALTH_POST_WIDGETS: WidgetName[] = [
  WIDGETS_NAMES_IN_SERVER.healthFooter,
  WIDGETS_NAMES_IN_SERVER.healthAboveComments,
  WIDGETS_NAMES_IN_SERVER.healthBelowComments,
  WIDGETS_NAMES_IN_SERVER.healthAfterTheFirstParagraph,
  WIDGETS_NAMES_IN_SERVER.healthPostDynamic,
];
