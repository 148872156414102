import { render, staticRenderFns } from "./TheSlider.vue?vue&type=template&id=3ab4675a&scoped=true"
import script from "./TheSlider.vue?vue&type=script&lang=ts"
export * from "./TheSlider.vue?vue&type=script&lang=ts"
import style0 from "./TheSlider.vue?vue&type=style&index=0&id=3ab4675a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab4675a",
  null
  
)

export default component.exports