export const ADS_LABELS: Record<string, string> = {
  empty: '',
  partner: 'Партнёрский',
  partnership: '',
  ads: 'Реклама',
  promoAds: 'Реклама',
  'press-release': 'Пресс-релиз',
  social: 'Социальная реклама',
};

// Лейблы которые не обрабатываются как рекламные несмотря на ads и partnership флаги.
export const ADS_EXCEPTIONS: string[] = ['partnership'];
