// Mutations
export const SET_DISHES = 'setDishes';
export const SET_RECIPESCOUNT = 'set_recipesCount';
export const SET_ISANIMATE = 'SET_ISANIMATE';
export const SET_TRUEISFIXEDSUBMITBUTTON = 'setTrueIsFixedSubmitButton';
export const SET_FALSEISFIXEDSUBMITBUTTON = 'setFalseIsFixedSubmitButton';
export const SET_OPENEDTAB = 'setOpenedTab';
export const SET_ANIMATE = 'SET_ANIMATE';
export const SET_SORTDIRECTION = 'SET_sortDirection';
export const SET_DISHESTWICE = 'set_dishesTwice';
// Actions
export const FETCH_FILTERSETTINGS = 'fetchFilterSettings';
export const FETCH_RECIPESCOUNT = 'fetch_recipesCount';
export const FETCH_MENUS = 'fetchMenus';
export const FETCH_INGREDIENTS = 'searchIngredients';
export const ACTION_HANDLER_PHONE = 'ACTION_HANDLER_PHONE';
export const ACTION_HANDLER_TABLET = 'ACTION_HANDLER_TABLET';
export const ACTION_HANDLER_DESKTOP = 'ACTION_HANDLER_DESKTOP';
